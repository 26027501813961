<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: { AdvancedCropper: Cropper },
    props: {
        file: File,
        containerStyle: Object,
        imgStyle: Object,
        size: Object,
        componentsHeight: Number,
        aspectRatio: Number,
        error: Boolean,
        disabled: Boolean,
        quality: {
            type: Number,
            default: 1,
            validator(value) {
                return value >= 0 && value <= 1
            },
        },
    },
    data() {
        return {
            module: null,
            img: null,
            imgType: null,
            imgSize: null,
        }
    },
    computed: {
        canvasSize() {
            let { width, height } = this.size
            if (
                this.imgSize &&
                this.aspectRatio &&
                (width / this.imgSize.width > 1 ||
                    height / this.imgSize.height > 1)
            ) {
                if (width / this.imgSize.width > height / this.imgSize.height) {
                    width = this.imgSize.width
                    height = this.imgSize.width / this.aspectRatio
                } else {
                    height = this.imgSize.height
                    width = this.imgSize.height * this.aspectRatio
                }
            }

            return {
                width,
                height,
            }
        },
    },
    watch: {
        file: {
            immediate: true,
            handler(file, oldFile) {
                if (file && file.name !== oldFile?.name) {
                    this.toBase64(file).then((res) => {
                        this.img = res
                        this.imgType = file.type

                        const img = new Image()
                        img.onload = () => {
                            this.imgSize = {
                                width: img.width,
                                height: img.height,
                            }
                        }
                        img.src = res
                    })
                }
            },
        },
    },
    mounted() {
        this.module = this.$refs.cropper
    },
    methods: {
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()

                reader.readAsDataURL(file)

                reader.onload = () => {
                    resolve(reader.result)
                }
                reader.onerror = (error) => {
                    reject(error)
                }
            })
        },
        onChange(el) {
            if (el) {
                let file
                const self = this
                el.canvas.toBlob(
                    (blob) => {
                        file = new File([blob], self.file.name, {
                            type: this.imgType,
                        })
                        this.$emit('update:file', file)
                    },
                    this.imgType,
                    this.quality
                )
            }
        },
        defaultSize({ imageSize, visibleArea }) {
            return {
                width: (visibleArea || imageSize).width,
                height: (visibleArea || imageSize).height,
            }
        },
    },
}
</script>

<template lang="pug">
advanced-cropper.cropper.bg-g-ef.rounded.transition-bg-br(
    ref="cropper"
    v-if="img"
    :src="img"
    :canvas="canvasSize"
    :stencil-props="{ aspectRatio: aspectRatio }"
    :style="{height: `${componentsHeight}px`}"
    default-boundaries="fit"
    :default-size="defaultSize"
    :class="[error ? 'error' : 'g-ef', {'disabled': disabled}]"
    @change="onChange")

</template>

<style lang="scss">
.cropper {
    &.disabled {
        opacity: 0.5;
        &,
        * {
            pointer-events: none;
        }
    }
}
</style>
